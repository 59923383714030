define("discourse/plugins/DiscourseVirtmail/discourse/templates/addresses", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{i18n "discourse-virtmail.addresses.title"}}</h1>
  
  {{outlet}}
  
  */
  {
    "id": "PRRsllWu",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"discourse-virtmail.addresses.title\"],null]],[13],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/DiscourseVirtmail/discourse/templates/addresses.hbs",
    "isStrictMode": false
  });
});