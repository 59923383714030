define("discourse/plugins/DiscourseVirtmail/discourse/routes/discourse-virtmail-addresses-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    controllerName: "addresses-show",
    model(params) {
      if (params.id === "new") {
        return this.store.createRecord("address");
      }
      return this.store.find("address", params.id);
    },
    renderTemplate() {
      this.render("addresses-show");
    }
  });
});