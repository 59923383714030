define("discourse/plugins/DiscourseVirtmail/initializers/discourse-virtmail", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeDiscourseVirtmail(api) {
    api.addStorePluralization('address', 'addresses');
  }
  var _default = _exports.default = {
    name: "discourse-virtmail",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeDiscourseVirtmail);
    }
  };
});