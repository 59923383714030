define("discourse/plugins/DiscourseVirtmail/discourse/controllers/addresses-index", ["exports", "@ember/object", "@ember/controller", "I18n", "bootbox", "discourse/lib/ajax-error"], function (_exports, _object, _controller, _I18n, _bootbox, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    addresses: {},
    resetPassword(address) {
      address.resetPassword();
    },
    destroy(address) {
      return _bootbox.default.confirm(_I18n.default.t("discourse-virtmail.addresses.delete_confirm"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), result => {
        if (result) {
          address.destroyRecord().then(() => {
            this.model.removeObject(address);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "resetPassword", [_object.action], Object.getOwnPropertyDescriptor(_obj, "resetPassword"), _obj), _applyDecoratedDescriptor(_obj, "destroy", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroy"), _obj)), _obj));
});