define("discourse/plugins/DiscourseVirtmail/discourse/components/virtmail-list", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.collection}}
    <div class="values">
      {{#each this.collection as |value index|}}
        <div data-index={{index}} class="value">
          <DButton
            @action={{action "removeValue"}}
            @actionParam={{value}}
            @icon="times"
            @class="remove-value-btn btn-small"
          />
  
          <Input
            title={{value}}
            @value={{value}}
            class="value-input"
            {{on "focusout" (fn (action "changeValue") index)}}
          />
        </div>
      {{/each}}
    </div>
  {{/if}}
  
  <div class="simple-list-input">
    <Input
      @type="text"
      @value={{this.newValue}}
      placeholder={{i18n "admin.site_settings.simple_list.add_item"}}
      class="add-value-input"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
    />
  
    <DButton
      @action={{action "addValue"}}
      @actionParam={{this.newValue}}
      @disabled={{this.inputEmpty}}
      @icon="plus"
      @class="add-value-btn btn-small"
    />
  </div>
  
  */
  {
    "id": "4cWTzlkQ",
    "block": "[[[41,[30,0,[\"collection\"]],[[[1,\"  \"],[10,0],[14,0,\"values\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"collection\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,\"data-index\",[30,2]],[14,0,\"value\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@action\",\"@actionParam\",\"@icon\",\"@class\"],[[28,[37,4],[[30,0],\"removeValue\"],null],[30,1],\"times\",\"remove-value-btn btn-small\"]],null],[1,\"\\n\\n        \"],[8,[39,5],[[16,\"title\",[30,1]],[24,0,\"value-input\"],[4,[38,6],[\"focusout\",[28,[37,7],[[28,[37,4],[[30,0],\"changeValue\"],null],[30,2]],null]],null]],[[\"@value\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"simple-list-input\"],[12],[1,\"\\n  \"],[8,[39,5],[[16,\"placeholder\",[28,[37,8],[\"admin.site_settings.simple_list.add_item\"],null]],[24,0,\"add-value-input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"newValue\"]]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@action\",\"@actionParam\",\"@disabled\",\"@icon\",\"@class\"],[[28,[37,4],[[30,0],\"addValue\"],null],[30,0,[\"newValue\"]],[30,0,[\"inputEmpty\"]],\"plus\",\"add-value-btn btn-small\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"value\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"d-button\",\"action\",\"input\",\"on\",\"fn\",\"i18n\"]]",
    "moduleName": "discourse/plugins/DiscourseVirtmail/discourse/components/virtmail-list.hbs",
    "isStrictMode": false
  });
  let VirtmailList = _exports.default = (_dec = (0, _object.computed)("newValue"), (_class = class VirtmailList extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "inputDelimiter", null);
      _defineProperty(this, "newValue", "");
      _defineProperty(this, "collection", null);
      _defineProperty(this, "values", null);
    }
    didReceiveAttrs() {
      this._super(...arguments);
      this.set("collection", this.values || []);
    }
    keyDown(event) {
      if (event.which === 13) {
        this.addValue(this.newValue);
        return;
      }
    }
    changeValue(index, event) {
      this.collection.replace(index, 1, [event.target.value]);
      this.collection.arrayContentDidChange(index);
      this._saveValues();
    }
    addValue(newValue) {
      if (this.inputEmpty) {
        return;
      }
      this.set("newValue", null);
      this.collection.addObject(newValue);
      this._saveValues();
    }
    removeValue(value) {
      this.collection.removeObject(value);
      this._saveValues();
    }
    get inputEmpty() {
      return !this.newValue;
    }
    _saveValues() {
      this.set("values", this.collection);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "changeValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputEmpty", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "inputEmpty"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VirtmailList);
});