define("discourse/plugins/DiscourseVirtmail/discourse/routes/discourse-virtmail-addresses", ["exports", "@ember/object", "discourse/routes/discourse"], function (_exports, _object, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _discourse.default.extend((_obj = {
    controllerName: "addresses",
    renderTemplate() {
      this.render("addresses");
    },
    show(address) {
      this.transitionTo("discourse-virtmail.addresses.show", address.id);
    },
    new() {
      this.transitionTo("discourse-virtmail.addresses.new");
    }
  }, (_applyDecoratedDescriptor(_obj, "show", [_object.action], Object.getOwnPropertyDescriptor(_obj, "show"), _obj), _applyDecoratedDescriptor(_obj, "new", [_object.action], Object.getOwnPropertyDescriptor(_obj, "new"), _obj)), _obj));
});