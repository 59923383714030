define("discourse/plugins/DiscourseVirtmail/discourse/initializers/group-custom-field", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'group-custom-field',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.8.30', api => {
        api.modifyClass('model:group', {
          virtmail_domains: [],
          asJSON() {
            return Object.assign(this._super(), {
              custom_fields: {
                virtmail_domains: this.virtmail_domains
              }
            });
          }
        });
      });
    }
  };
});