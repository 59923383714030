define("discourse/plugins/DiscourseVirtmail/discourse/routes/discourse-virtmail-oauth2-authorize", ["exports", "@ember/object", "discourse/routes/discourse"], function (_exports, _object, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    controllerName: "oauth2-authorize",
    renderTemplate() {
      this.render("oauth2-authorize");
    }
  });
});