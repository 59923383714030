define("discourse/plugins/DiscourseVirtmail/discourse/templates/connectors/group-edit/field-container", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if currentUser.admin}}
    <div class="control-group">
      <label class="control-label">{{i18n "discourse-virtmail.addresses.virtmail_domains"}}</label>
      <VirtmailList @values={{group.virtmail_domains}} />
    </div>
  {{/if}}
  
  */
  {
    "id": "ZC5/jX5W",
    "block": "[[[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"discourse-virtmail.addresses.virtmail_domains\"],null]],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@values\"],[[30,0,[\"group\",\"virtmail_domains\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/DiscourseVirtmail/discourse/templates/connectors/group-edit/field-container.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `group` property path was used in the `discourse/plugins/DiscourseVirtmail/discourse/templates/connectors/group-edit/field-container.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.group}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\",\"virtmail-list\"]]",
    "moduleName": "discourse/plugins/DiscourseVirtmail/discourse/templates/connectors/group-edit/field-container.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});