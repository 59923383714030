define("discourse/plugins/DiscourseVirtmail/discourse/models/address", ["exports", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    createProperties() {
      return this.getProperties("domain", "localpart", "comment", "destinations", "forward_only", "quota_bytes", "allowed_users");
    },
    updateProperties() {
      return this.createProperties();
    },
    resetPassword() {
      const path = this.store.adapterFor("address").pathFor(this.store, "address", this.id);
      return (0, _ajax.ajax)(`${path}/reset_password`, {
        type: "POST"
      }).then(result => this.set("password", result.password));
    }
  });
});