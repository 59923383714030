define("discourse/plugins/DiscourseVirtmail/discourse/controllers/addresses-show", ["exports", "@ember/object", "@ember/controller", "I18n", "@ember/object/computed", "bootbox", "discourse-common/utils/decorators", "discourse/lib/utilities", "discourse-common/lib/get-url", "@ember/utils", "discourse/lib/ajax-error"], function (_exports, _object, _controller, _I18n, _computed, _bootbox, _decorators, _utilities, _getUrl, _utils, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  // import Controller from "@ember/controller";
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("model.isSaving", "saved", "saveButtonDisabled"), _dec2 = (0, _decorators.default)("model.isNew"), (_obj = {
    // addresses: controller(),

    addresses: {},
    savingStatus(isSaving, saved, saveButtonDisabled) {
      if (isSaving) {
        return _I18n.default.t("saving");
      } else if (!saveButtonDisabled && saved) {
        return _I18n.default.t("saved");
      }
      // Use side effect of validation to clear saved text
      this.set("saved", false);
      return "";
    },
    saveButtonText(isNew) {
      return isNew ? _I18n.default.t("admin.web_hooks.create") : _I18n.default.t("admin.web_hooks.save");
    },
    save() {
      this.set("saved", false);
      const model = this.model;
      const isNew = model.get("isNew");
      return model.save().then(() => {
        this.set("saved", true);
        if (isNew) {
          this.transitionToRoute("discourse-virtmail.addresses.show", model.get("id"));
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_applyDecoratedDescriptor(_obj, "savingStatus", [_dec], Object.getOwnPropertyDescriptor(_obj, "savingStatus"), _obj), _applyDecoratedDescriptor(_obj, "saveButtonText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "saveButtonText"), _obj), _applyDecoratedDescriptor(_obj, "save", [_object.action], Object.getOwnPropertyDescriptor(_obj, "save"), _obj)), _obj)));
});