define("discourse/plugins/DiscourseVirtmail/discourse/controllers/oauth2-authorize", ["exports", "@ember/object", "@ember/controller", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _controller, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _object.computed)("addresses"), (_obj = {
    addresses: [],
    init() {
      this._super(arguments);
      (0, _ajax.ajax)(`/discourse-virtmail/oauth2/authorize`, {
        type: "GET"
      }).then(result => {
        this.set("addresses", result.addresses);
      }).catch(_ajaxError.popupAjaxError);
    },
    get hasAddresses() {
      // console.log("hasAddresses")
      return true;
      return !!this.addresses.length;
    },
    authorize(email) {
      return (0, _ajax.ajax)(`/discourse-virtmail/oauth2/authorize`, {
        type: "POST",
        data: {
          email: email,
          search: location.search.substr(1)
        }
      }).then(result => {
        location.href = result.location;
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_applyDecoratedDescriptor(_obj, "hasAddresses", [_dec], Object.getOwnPropertyDescriptor(_obj, "hasAddresses"), _obj), _applyDecoratedDescriptor(_obj, "authorize", [_object.action], Object.getOwnPropertyDescriptor(_obj, "authorize"), _obj)), _obj)));
});